import { useEffect } from 'react'
import { useSuspenseQuery, UseSuspenseQueryOptions } from '@tanstack/react-query'
import { ITableResource } from 'common/interfaces'

export type UseTableSuspenseQueryProps<TRow> = UseSuspenseQueryOptions<ITableResource<TRow>> & {
  onPageChange: (page: number) => void
}

const useTableSuspenseQuery = <TRow,>({ onPageChange, ...queryOptions }: UseTableSuspenseQueryProps<TRow>) => {
  const query = useSuspenseQuery(queryOptions)

  useEffect(() => {
    if (query.data && query.data.current_page > query.data.last_page && !query.isFetching) {
      onPageChange(query.data.last_page)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data])

  return query
}

export default useTableSuspenseQuery
