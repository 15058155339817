import axios, { AxiosError } from 'axios'
import { IResponse } from '../interfaces'
import { doRefreshToken, doHardLogout } from '../api/authorization'

const axiosInstance = axios.create({
  timeout: 10000,
  headers: {
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use((req) => {
  const access_token = localStorage.getItem('access_token')

  if (!!access_token) {
    req.headers.Authorization = `Bearer ${access_token}`
  }

  return req
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res
  },
  (err: AxiosError<IResponse<any>>) => {
    const refresh_token = localStorage.getItem('refresh_token')

    if (err.response?.status === 401) {
      if (!!refresh_token) {
        return doRefreshToken({ token: refresh_token })
          .then((res) => {
            const access_token = res.data.resource.access_token

            if (!!err.config) {
              err.config.headers.Authorization = `Bearer ${access_token}`

              return axiosInstance(err.config)
            }
          })
          .catch(() => {
            doHardLogout()
          })
      }
    }

    return Promise.reject(err)
  },
)

export default axiosInstance
