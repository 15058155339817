import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const DEFAULT_IMAGE = '/images/wetek-android-meeting-1-scaled.jpg'

const imageMap = {
  '/login': DEFAULT_IMAGE,
  '/auth/password/forgot': '/images/wetek-password-forgot.jpg',
  '/auth/password/reset': '/images/wetek-password-reset.jpg',
  '/auth/unlock': '/images/wetek-unlock-account.jpg',
}

const AuthLayout = () => {
  const { pathname } = useLocation()

  const imageSrc = imageMap[pathname] || DEFAULT_IMAGE

  return (
    <main className="w-full min-h-full">
      <div className="flex h-screen backdrop-opacity-5">
        <div className="flex flex-col flex-1 justify-center lg:flex-none px-4 py-12 sm:px-6 lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="flex justify-center">
              <img className="h-28" src="/images/agile-logo-suit-negative.png" alt="Logo WBC" />
            </div>
            <Outlet />
          </div>
        </div>
        <div className="relative hidden lg:block flex-1 w-0">
          <img className="w-full h-full object-cover overflow-hidden" src={imageSrc} alt="Workflow" />
        </div>
      </div>
    </main>
  )
}

export default AuthLayout
