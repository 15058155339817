import React, { useMemo } from 'react'
import { Button, ButtonProps, CircularProgress, CircularProgressProps } from '@mui/material'

export type AsyncButtonProps = ButtonProps & {
  caption?: string
  isDisabled?: boolean
  isPending?: boolean
  circularProgressProps?: CircularProgressProps
}

const AsyncButton = ({ caption, isDisabled, isPending, circularProgressProps, ...props }: AsyncButtonProps) => {
  const startIcon = useMemo(() => (isPending ? undefined : props.startIcon), [isPending, props.startIcon])

  const disabled = isDisabled || isPending

  return (
    <Button {...props} variant="contained" startIcon={startIcon} disabled={disabled}>
      {isPending ? <CircularProgress {...circularProgressProps} color="primary" size={24.5} /> : caption || 'Ok'}
    </Button>
  )
}

export default AsyncButton
