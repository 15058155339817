import React from 'react'
import { twMerge } from 'tailwind-merge'

type StatusProps = {
  statuses?: { id: string | number; name: string; color: string }[]
  status: string | number
  simple?: boolean
  className?: string
}

const defaultStatuses = [
  {
    id: 0,
    name: 'Inactive',
    color: 'bg-red',
  },
  {
    id: 1,
    name: 'Active',
    color: 'bg-green-500',
  },
  {
    id: 2,
    name: 'Pending',
    color: 'bg-blue-500',
  },
  {
    id: 3,
    name: 'Unknown',
    color: 'bg-primary',
  },
]

const Status = ({ statuses = defaultStatuses, status, simple }: StatusProps) => {
  const render = statuses.find((s) => s.id === status) || statuses[0]

  return (
    <span
      className={twMerge(
        'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-gray-500/10',
        render.color,
        simple && 'w-3 h-3 rounded-full p-0',
      )}
    >
      {!simple ? render.name : null}
    </span>
  )
}

export default Status
