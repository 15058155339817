import axios, { AxiosResponse } from 'axios'
import { AUTH_BASE_URL } from '../../API_routes'
import {
  IResponse,
  IResponseError,
  IResponseDetailedError,
  IAuthCredentials,
  IAuthToken,
  IAuth2FA,
  IAuthOption,
} from '../interfaces'

const apiPrefix = `${AUTH_BASE_URL}/auth`

export const saveAuthToken = (res: AxiosResponse<IResponse<IAuthToken>>) => {
  const {
    data: {
      resource: { access_token, refresh_token },
    },
  } = res

  localStorage.setItem('access_token', access_token)
  localStorage.setItem('refresh_token', refresh_token)
}

export const clearAuthToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}

export const doLogin = ({ email, password }: IAuthCredentials) =>
  axios
    .post<IResponse<IAuthToken | IAuth2FA, IResponseError>>(
      `${apiPrefix}/login`,
      {},
      { headers: { Authorization: `Basic ${btoa(`${email}:${password}`)}` } },
    )
    .then((res) => {
      if ('access_token' in res.data.resource) {
        saveAuthToken(res as AxiosResponse<IResponse<IAuthToken, IResponseError>>)
      }

      return res
    })

export const doLoginOtp = ({ token }: { token: string }) =>
  axios.post<IResponse<IAuthToken, IResponseError>>(`${apiPrefix}/login/otp/${token}`).then((res) => {
    saveAuthToken(res)

    return res
  })

export const doRefreshToken = ({ token }: { token: string }) =>
  axios
    .post<IResponse<IAuthToken>>(`${apiPrefix}/refresh-token`, {}, { headers: { Authorization: `Bearer ${token}` } })
    .then((res) => {
      saveAuthToken(res)

      return res
    })

export const doHardLogout = () => {
  clearAuthToken()

  document.location.replace('/login')
}

export const forgotPassword = (data: { email: string }) =>
  axios.put<IResponse<null, IResponseDetailedError>>(`${apiPrefix}/password/forgot`, data)

export const resetPassword = ({ token, ...data }: { token: string; password: string; password_confirmation: string }) =>
  axios.put<IResponse<null, IResponseDetailedError>>(`${apiPrefix}/password/reset`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

export const unlockAccount = ({ token }: { token: string }) =>
  axios.put<IResponse<null>>(
    `${apiPrefix}/unlock`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )

export const getLoginOptions = () => axios.get<IResponse<IAuthOption[]>>(`${AUTH_BASE_URL}/public/login/options`)
