import { useState, useRef, useEffect, RefObject } from 'react'

const useIsIntersecting = (elementRef: RefObject<HTMLElement>, observerOptions?: IntersectionObserverInit) => {
  const [isIntersecting, setIsIntersecting] = useState(false)

  const observerRef = useRef(
    new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), observerOptions),
  )

  useEffect(() => {
    const element = elementRef.current
    const observer = observerRef.current

    if (isIntersecting) {
      observer.disconnect()

      return
    }

    if (!isIntersecting && !!element) {
      observer.observe(element)
    }
  }, [elementRef, isIntersecting])

  return isIntersecting
}

export default useIsIntersecting
