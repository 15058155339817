import { AUTH_BASE_URL } from '../../API_routes'
import { IResponse, IRBAC } from '../interfaces'
import { IUser } from '../models/user'
import axiosInstance from '../axiosInstance'
import { clearAuthToken } from './authorization'

const apiPrefix = `${AUTH_BASE_URL}/auth`
const apiPrefixList = `${AUTH_BASE_URL}/list`

export const checkAuthentication = () =>
  axiosInstance
    .get<IResponse<null>>(`${apiPrefix}/check-authentication`)
    .then((res) => res.status === 200)
    .catch(() => false)

export const doLogout = () =>
  axiosInstance.get<IResponse<null>>(`${apiPrefix}/logout`).then((res) => {
    clearAuthToken()

    return res
  })

export const getUserProfile = () => axiosInstance.get<IResponse<IUser>>(`${apiPrefix}/profile`)

export const getRBAC = () => axiosInstance.get<IResponse<IRBAC[]>>(`${apiPrefixList}/rbac`)
