import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { IResponse, IResponseDetailedError } from 'common/interfaces'
import { resetPassword } from 'common/api/authorization'
import { Alert } from 'components/ui'
import SubmitButton from 'components/forms/submit-button'

type FormValues = {
  password: string
  password_confirmation: string
}

const ResetPasswordPage = () => {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | undefined>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<FormValues>()
  const mutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      const timeoutId = setTimeout(() => navigate('/login', { replace: true }), 3000)

      setTimeoutId(timeoutId)
    },
    meta: {
      skipDefaultOnSuccess: true,
      skipDefaultOnError: true,
    },
  })

  const { data: mutationData, isPending, isSuccess, isError, mutate } = mutation

  const error = isError
    ? (mutation.error as AxiosError<IResponse<null, IResponseDetailedError>>).response?.data.error
    : undefined

  const token = searchParams.get('token')

  const isDisabled = isSuccess || !token

  const onSubmit: SubmitHandler<FormValues> = (formValues) => {
    if (token) {
      return mutate({ token, ...formValues })
    }
  }

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId])

  return (
    <>
      <h2 className="mb-8 text-center text-primary text-3xl font-bold tracking-tight">Reset your password</h2>
      {isSuccess && (
        <Alert className="mb-4" severity="success" title={mutationData.data.message}>
          You will be redirected back to the login page in a few seconds.
        </Alert>
      )}
      {isError && (
        <Alert className="mb-4" severity="error" title={error?.message}>
          {Object.entries(error?.details || {}).map(([key, value]) => (
            <div key={key}>{value}</div>
          ))}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextField label="Password" variant="outlined" fullWidth type="password" required {...register('password')} />
        </div>
        <div className="mb-4">
          <TextField
            label="Confirm password"
            variant="outlined"
            fullWidth
            type="password"
            required
            {...register('password_confirmation')}
          />
        </div>
        <div className="flex justify-end mt-6">
          <SubmitButton caption="Reset password" isDisabled={isDisabled} isPending={isPending} />
        </div>
      </form>
    </>
  )
}

export default ResetPasswordPage
