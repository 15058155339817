import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import {
  IDevice,
  IDeviceData,
  GetDevicesParams,
  CreateDeviceParams,
  UpdateDeviceParams,
  ImportDevicesParams,
} from '../models/device'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-devices`

export const getDevices = ({
  uid,
  provision_id,
  operator_device_type_id,
  name,
  serial_number,
  mac_address,
  release_cycle,
  status,
  orderBy,
  order,
  limit,
  page,
}: GetDevicesParams = {}) =>
  axios.get<IResponse<ITableResource<IDevice>>>(apiPrefix, {
    params: {
      'filter[uid]': uid,
      'filter[provision_id]': provision_id,
      'filter[operator_device_type_id]': operator_device_type_id,
      'filter[name]': name,
      'filter[serial_number]': serial_number,
      'filter[mac_address]': mac_address,
      'filter[release_cycle]': release_cycle,
      'filter[status]': status,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getDeviceById = (id: string) => axios.get<IResponse<IDevice>>(`${apiPrefix}/${id}`)

export const getDeviceByPairingCode = (pairing_code: string) =>
  axios.get<IResponse<ITableResource<IDevice>>>(apiPrefix, {
    params: {
      'filter[pairing_code]': pairing_code,
    },
  })

export const createDevice = (data: CreateDeviceParams) => axios.post<IResponse<IDevice>>(apiPrefix, data)

export const updateDevice = ({ id, ...data }: UpdateDeviceParams) =>
  axios.put<IResponse<IDevice>>(`${apiPrefix}/${id}`, data)

export const lockDevice = ({ id, ...data }: { id: string; lock: 1 | 0 }) =>
  axios.patch<IResponse<IDevice>>(`${apiPrefix}/${id}/lock`, data)

export const deleteDevices = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const importDevices = (data: ImportDevicesParams) =>
  axios.postForm<IResponse<string>>(`${apiPrefix}/import`, data)

export const getDeviceData = (id: string) => axios.get<IResponse<IDeviceData>>(`${apiPrefix}/data/${id}`)

export const getDeviceStatusV2 = (id: string) => axios.get<IResponse<object>>(`${apiPrefix}/status/${id}`)

export const getDeviceStatus = (mac: string) =>
  axios.get<IResponse<ITableResource<object>>>(`${CORE_API_DOMAIN}/query/status`, {
    params: {
      'list[]': true,
      mac_address_values: mac,
      order: 'updated_at',
      direction: 'desc',
    },
  })
