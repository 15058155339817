import React from 'react'
import AsyncButton, { AsyncButtonProps } from 'components/ui/async-button'

export type SubmitButtonProps = AsyncButtonProps

const SubmitButton = (props: SubmitButtonProps) => {
  return <AsyncButton type="submit" caption="Submit" {...props} />
}

export default SubmitButton
