import { useCallback } from 'react'
import useAuth from './useAuth'

export type HasPermissionsParams = {
  route?: string
  allowedRoles?: string[]
  forbiddenRoles?: string[]
}

const usePermissions = () => {
  const { user, rbac } = useAuth()

  const hasPermissions = useCallback(
    ({ route, allowedRoles, forbiddenRoles }: HasPermissionsParams = {}) => {
      if (!user || !rbac) return false

      if (!!route && (!rbac[route] || !rbac[route].includes(user.role_id))) return false

      if (!!allowedRoles && !allowedRoles.includes(user.role_id)) return false

      if (!!forbiddenRoles && forbiddenRoles.includes(user.role_id)) return false

      return true
    },
    [user, rbac],
  )

  return hasPermissions
}

export default usePermissions
