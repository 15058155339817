export const wbcVersion = `${process.env.REACT_APP_WBC_VERSION}`

export const remoteAssistanceSupportedVersion = `${process.env.REACT_APP_NEW_REMOTE_ACTION}`
export const remoteAssistanceSecureConnectVersion = '2.0.55.0'

const protocol = `${process.env.REACT_APP_WBC_ACCESS_PROTOCOL}://`
const version = `${process.env.REACT_APP_PHP_API_VERSION}`

export const AUTH_BASE_URL = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_AUTH}${version}`
export const CORE_API_DOMAIN = `${protocol}${process.env.REACT_APP_PHP_DOMAIN_CORE}${version}`
