import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import {
  IBulkAction,
  GetBulkActionsParams,
  GetBulkActionsPreviewParams,
  CreateBulkActionParams,
} from '../models/bulk-action'
import { IDevice } from '../models/device'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/bulk-actions`

export const getBulkActions = ({
  //
  orderBy,
  order,
  limit,
  page,
}: GetBulkActionsParams = {}) =>
  axios.get<IResponse<ITableResource<IBulkAction>>>(apiPrefix, {
    params: {
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getBulkActionsPreview = ({
  //
  orderBy,
  order,
  limit,
  page,
  ...data
}: GetBulkActionsPreviewParams) =>
  axios.postForm<IResponse<ITableResource<IDevice>>>(`${apiPrefix}/preview`, data, {
    params: {
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const createBulkAction = (data: CreateBulkActionParams) =>
  axios.postForm<IResponse<IBulkAction>>(apiPrefix, data)
