import { Navigate } from 'react-router-dom'
import { homeUrlByRole } from 'common/navigation'
import useAuth from 'hooks/useAuth'

const IndexRedirect = () => {
  const { user } = useAuth()

  const role = user!.role_id
  const url = homeUrlByRole[role]

  if (!url) return null

  return <Navigate to={url} replace />
}

export default IndexRedirect
