import { useState, useCallback } from 'react'
import { Filter } from 'common/types'
import { Order } from 'common/enums'

const defaultTableState = {
  page: 1,
  orderBy: 'created_at',
  order: Order.ASC,
  filter: undefined,
}

export type TableState<TFilter extends Filter> = {
  page: number
  orderBy: string
  order: Order
  filter?: TFilter
}

export type InitialTableState<TFilter extends Filter> =
  | Partial<TableState<TFilter>>
  | (() => Partial<TableState<TFilter>>)

export type UseTableStateReturn<TFilter extends Filter> = TableState<TFilter> & {
  setPage: (page: number) => void
  setOrderBy: (orderBy: string) => void
  setOrder: (order: Order) => void
  setFilter: (filter?: TFilter) => void
}

function useTableState<TFilter extends Filter>(
  initialState: InitialTableState<TFilter> = {},
): UseTableStateReturn<TFilter> {
  const [tableState, setTableState] = useState<TableState<TFilter>>(
    typeof initialState === 'function'
      ? () => ({ ...defaultTableState, ...initialState() })
      : { ...defaultTableState, ...initialState },
  )

  const setPage = useCallback((page: number) => {
    setTableState((prev) => ({ ...prev, page }))
  }, [])

  const setOrderBy = useCallback((orderBy: string) => {
    setTableState((prev) => ({ ...prev, orderBy }))
  }, [])

  const setOrder = useCallback((order: Order) => {
    setTableState((prev) => ({ ...prev, order }))
  }, [])

  const setFilter = useCallback((filter?: TFilter) => {
    setTableState((prev) => ({ ...prev, page: 1, filter }))
  }, [])

  return {
    ...tableState,
    setPage,
    setOrderBy,
    setOrder,
    setFilter,
  }
}

export default useTableState
