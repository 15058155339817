import React from 'react'
import { AxiosResponse } from 'axios'
import { IResponse, IResponseError, IResponseDetailedError } from 'common/interfaces'

export type ToastErrorProps = {
  response?: AxiosResponse<IResponse<any, IResponseError | IResponseDetailedError | string | undefined>>
}

const ToastError = ({ response }: ToastErrorProps) => {
  if (!response) return <div>Error!</div>

  const { data } = response

  let message: string = ''
  let details: React.ReactElement[] | null = null

  if (data.message) {
    message += data.message + '. '
  }

  if (data.error) {
    if (typeof data.error === 'string') {
      message += data.error + '. '
    } else if (!('details' in data.error)) {
      message += data.error.message + '. '
    } else {
      message += data.error.message + ':'

      details = Object.values(data.error.details).map((detail) => <div key={detail}>{`- ${detail}`}</div>)
    }
  }

  message = message.replace(/\.\./g, '.').replace(/::/g, ':').replace(/\.:/g, ':').trim()

  return (
    <>
      <div className={!!details ? 'font-bold' : ''}>{message || 'Error!'}</div>
      {details}
    </>
  )
}

export default ToastError
