import React, { useMemo } from 'react'
import { useSearchParams, useLocation, useNavigate, Link } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useQuery, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { TextField, Button } from '@mui/material'
import { IResponse, IResponseError, IAuthCredentials, IAuthToken, IAuth2FA } from 'common/interfaces'
import { doLogin } from 'common/api/authorization'
import { loginOptions } from 'common/query-options'
import useAuth from 'hooks/useAuth'
import { Alert } from 'components/ui'
import Loader from 'components/loader'
import SubmitButton from 'components/forms/submit-button'

const LoginPage = () => {
  const { getAuthentication } = useAuth()

  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()

  const slug = searchParams.get('operator')
  const from = location.state?.from?.pathname || '/'

  const loginOptionsQuery = useQuery({ ...loginOptions(), enabled: !!slug })

  const sso = useMemo(
    () => (!!loginOptionsQuery.data ? loginOptionsQuery.data.find((option) => option.slug === slug) : undefined),
    [loginOptionsQuery.data, slug],
  )

  const loginMutation = useMutation({
    mutationFn: doLogin,
    onSuccess: (res) => {
      if ('access_token' in res.data.resource) {
        getAuthentication(() => {
          navigate(from, { replace: true })
        })
      } else {
        const from = location.state?.from

        navigate('/auth/2fa', { state: { from }, replace: true })
      }
    },
    meta: {
      skipDefaultOnError: true,
    },
  })

  const { isPending, isError, mutate } = loginMutation

  const error = isError
    ? (loginMutation.error as AxiosError<IResponse<IAuthToken | IAuth2FA, IResponseError>>).response?.data
    : undefined

  const { register, handleSubmit } = useForm<IAuthCredentials>()

  const onSubmit: SubmitHandler<IAuthCredentials> = (credentials) => mutate(credentials)

  if (loginOptionsQuery.isFetching) return <Loader />

  if (!!sso) {
    return (
      <>
        <h2 className="mb-8 text-center text-primary text-3xl font-bold tracking-tight">
          Sign in with your operator account
        </h2>
        <div className="flex justify-center items-center gap-4 mb-4">
          <img className="h-8" src={sso.logo} alt="Operator logo" />
          <h3 className="text-xl font-bold">{sso.name}</h3>
        </div>
        <Button variant="contained" fullWidth href={sso.redirect_url}>
          {sso.redirect_label}
        </Button>
      </>
    )
  }

  return (
    <>
      <h2 className="mb-8 text-center text-primary text-3xl font-bold tracking-tight">Sign in to your account</h2>
      {isError && <Alert className="mb-4" title={error?.message} text={error?.error.message} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextField
            type="email"
            autoComplete="email"
            label="Email address"
            placeholder="Email address"
            variant="outlined"
            fullWidth
            required
            {...register('email')}
          />
        </div>
        <div className="mb-4">
          <TextField
            type="password"
            autoComplete="password"
            label="Password"
            placeholder="Password"
            variant="outlined"
            fullWidth
            required
            {...register('password')}
          />
        </div>
        <div className="flex justify-between items-center mt-6">
          <Link to="/auth/password/forgot">Forgot password?</Link>
          <SubmitButton caption="Log in" isPending={isPending} />
        </div>
      </form>
    </>
  )
}

export default LoginPage
