import { AUTH_BASE_URL } from '../../API_routes'
import { IResponse, ITableResource, ISelectOption } from '../interfaces'
import { IUser, GetUsersParams, CreateUserParams, UpdateUserParams } from '../models/user'
import axios from '../axiosInstance'

const apiPrefix = `${AUTH_BASE_URL}/users`
const apiPrefixList = `${AUTH_BASE_URL}/list`

export const getUsers = ({
  //
  role_id,
  operator_id,
  name,
  email,
  status,
  orderBy,
  order,
  limit,
  page,
}: GetUsersParams) =>
  axios.get<IResponse<ITableResource<IUser>>>(apiPrefix, {
    params: {
      'filter[role_id]': role_id,
      'filter[operator_id]': operator_id,
      'filter[name]': name,
      'filter[email]': email,
      'filter[status]': status,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const createUser = (data: CreateUserParams) => axios.post<IResponse<IUser>>(apiPrefix, data)

export const updateUser = ({ id, ...data }: UpdateUserParams) => axios.put<IResponse<IUser>>(`${apiPrefix}/${id}`, data)

export const deleteUsers = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const getUserRoles = () => axios.get<IResponse<ISelectOption[]>>(`${apiPrefixList}/roles`)

export const getUserOperators = () => axios.get<IResponse<ISelectOption[]>>(`${apiPrefixList}/operators`)
