import React, { useRef, useMemo } from 'react'
import { VictoryStyleInterface, VictoryPie, VictoryTooltip } from 'victory'
import { useVictoryScrollDelay } from 'hooks'

const animate = { duration: 500, onLoad: { duration: 500 } }

export type ChartPieProps = {
  data: { x: number | string | undefined; y: number }[]
  style?: VictoryStyleInterface
  colorScale: string[]
}

const ChartPie = ({ data, style, colorScale }: ChartPieProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [visibleData, isVisible] = useVictoryScrollDelay(data, ref)

  const styleProp = useMemo(
    () => ({
      data: { stroke: '#fff', strokeWidth: 2 },
      ...style,
    }),
    [style],
  )

  const total = useMemo(() => data.reduce((sum, { y }) => sum + y, 0), [data])

  const labels = useMemo(
    () =>
      data.map(({ x, y }) => {
        const percent = ((y / total) * 100).toFixed(1)

        return `${x} (${percent}%)`
      }),
    [data, total],
  )

  return (
    <div ref={ref}>
      <VictoryPie
        data={visibleData}
        style={styleProp}
        colorScale={colorScale}
        endAngle={isVisible ? 360 : 0}
        animate={animate}
        labels={labels}
        labelComponent={<VictoryTooltip />}
      />
    </div>
  )
}

export default ChartPie
