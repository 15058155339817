import React, { useCallback } from 'react'
import { UseControllerProps, FieldValues, FieldPath, useController } from 'react-hook-form'

export type OmitComponentProps<TInputComponentProps> = Omit<
  TInputComponentProps,
  'name' | 'value' | 'defaultValue' | 'checked' | 'defaultChecked' | 'disabled' | 'readOnly' | 'onChange' | 'onBlur'
>

export type UseControllerPlusProps<
  TInputComponentProps,
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TInputValue = any,
  TChangeEvent = React.ChangeEvent<HTMLInputElement>,
> = OmitComponentProps<TInputComponentProps> & {
  readOnly?: boolean
  transformInput?: (value: any) => TInputValue
  transformOutput?: (e: TChangeEvent) => any
} & UseControllerProps<TFieldValues, TFieldPath>

const useControllerPlus = <
  TInputComponentProps,
  TFieldValues extends FieldValues = FieldValues,
  TFieldPath extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TInputValue = any,
  TChangeEvent = React.ChangeEvent<HTMLInputElement>,
>({
  control,
  rules,
  shouldUnregister,
  name,
  defaultValue,
  disabled,
  readOnly,
  transformInput,
  transformOutput,
  ...rest
}: UseControllerPlusProps<TInputComponentProps, TFieldValues, TFieldPath, TInputValue, TChangeEvent>) => {
  const fieldOwnProps = rest as OmitComponentProps<TInputComponentProps>

  const { field, fieldState, formState } = useController<TFieldValues, TFieldPath>({
    control,
    rules,
    shouldUnregister,
    name,
    defaultValue,
    disabled,
  })

  const _value = !!transformInput ? transformInput(field.value) : field.value

  const _onChange = useCallback(
    (e: TChangeEvent) => (!!transformOutput ? field.onChange(transformOutput(e)) : field.onChange(e)),
    [field, transformOutput],
  )

  const _disabled = field.disabled || readOnly

  return {
    field: {
      ...field,
      value: _value,
      onChange: _onChange,
      disabled: _disabled,
    },
    fieldOwnProps,
    fieldState,
    formState,
  }
}

export default useControllerPlus
