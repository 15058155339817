import { CORE_API_DOMAIN, AUTH_BASE_URL } from '../../API_routes'
import {
  IResponse,
  ISelectOption,
  IAuthStatistics,
  ICoreStatistics,
  IDeviceProfile,
  IRom,
  IBoxStatistics,
  IOperatorDevicesActivity,
} from '../interfaces'
import axios from '../axiosInstance'

export const getRoles = () => axios.get<IResponse<ISelectOption[]>>(`${AUTH_BASE_URL}/list/roles`)

export const getDashboardAuthStatistics = () =>
  axios.get<IResponse<IAuthStatistics>>(`${AUTH_BASE_URL}/dashboard/statistics`)

export const getDashboardCoreStatistics = () =>
  axios.get<IResponse<ICoreStatistics>>(`${CORE_API_DOMAIN}/dashboard/statistics`)

export const getDashboardDeviceProfiles = () =>
  axios.get<IResponse<IDeviceProfile[]>>(`${CORE_API_DOMAIN}/dashboard/deviceProfiles`)

export const getDashboardRoms = () => axios.get<IResponse<IRom[]>>(`${CORE_API_DOMAIN}/dashboard/rom`)

export const getDashboardBoxStatistics = () =>
  axios.get<IResponse<IBoxStatistics>>(`${CORE_API_DOMAIN}/dashboard/box/statistics`)

export const getOperatorDevicesActivity = ({ profileId, timestamp }: { profileId?: string; timestamp?: number }) =>
  axios.get<IResponse<IOperatorDevicesActivity>>(`${CORE_API_DOMAIN}/operator-devices/activity`, {
    params: {
      'filter[profile_id]': profileId,
      'filter[timestamp]': timestamp,
    },
  })
