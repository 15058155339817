import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IResponse, IResponseError, IAuthToken } from 'common/interfaces'
import { doLoginOtp } from 'common/api/authorization'
import useAuth from 'hooks/useAuth'
import { Alert } from 'components/ui'
import Loader from 'components/loader'

const LoginOtpPage = () => {
  const { getAuthentication } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: doLoginOtp,
    onSuccess: () => {
      getAuthentication(() => navigate('/', { replace: true }))
    },
    meta: {
      skipDefaultOnError: true,
    },
  })

  const { isError, mutate } = mutation

  const error = isError
    ? (mutation.error as AxiosError<IResponse<IAuthToken, IResponseError>>).response?.data
    : undefined

  const token = searchParams.get('otp_token')

  useEffect(() => {
    mutate({ token: token || '' })
  }, [token, mutate])

  if (isError) {
    return (
      <Alert severity="error" title={error?.message}>
        {error?.error.message}
      </Alert>
    )
  }

  return <Loader />
}

export default LoginOtpPage
