import { CommandRequest } from './socket.io'
import { RemoteActionId, RemoteActionType } from './enums'

export const keyPressAction = ({ key_code }: { key_code: number }): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code,
})

export const keyPressLeftAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_LEFT,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 122,
})

export const keyPressRightAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_RIGHT,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 123,
})

export const keyPressBackAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_BACK,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 4,
})

export const keyPressAppsAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_APPS,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 3,
})

export const keyPressMuteAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_MUTE,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 164,
})

export const keyPressDpadUpAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_DPAD_UP,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 19,
})

export const keyPressDpadDownAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_DPAD_DOWN,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 20,
})

export const keyPressDpadLeftAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_DPAD_LEFT,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 21,
})

export const keyPressDpadRightAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_DPAD_RIGHT,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 22,
})

export const keyPressDpadCenterAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_DPAD_CENTER,
  remote_action_type: RemoteActionType.KEY_PRESS,
  screenshot: true,
  key_code: 23,
})

export const tapScreenAction = ({ x, y }: { x: number; y: number }): CommandRequest => ({
  remote_action_id: RemoteActionId.TAP_SCREEN,
  remote_action_type: RemoteActionType.TAP_SCREEN,
  screenshot: true,
  x,
  y,
})

export const inputTextAction = (input_text: string): CommandRequest => ({
  remote_action_id: RemoteActionId.INPUT_TEXT,
  remote_action_type: RemoteActionType.INPUT_TEXT,
  screenshot: true,
  input_text,
})

export const firstPingAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.FIRST_PING,
  remote_action_type: RemoteActionType.PING,
})

export const pingAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.PING,
  remote_action_type: RemoteActionType.PING,
})

export const screenshotAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.SCREENSHOT,
  remote_action_type: RemoteActionType.SCREENSHOT,
  screenshot: true,
})

export const downloadScreenshotAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.DOWNLOAD_SCREENSHOT,
  remote_action_type: RemoteActionType.SCREENSHOT,
  screenshot: true,
})

export const installAppAction = ({ apk_url }: { apk_url: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.INSTALL_APP,
  remote_action_type: RemoteActionType.INSTALL_APP,
  screenshot: true,
  apk_url,
})

export const openAppAction = ({ package_name }: { package_name: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.OPEN_APP,
  remote_action_type: RemoteActionType.OPEN_APP,
  screenshot: true,
  package_name,
})

export const uninstallAppAction = ({ package_name }: { package_name: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.UNINSTALL_APP,
  remote_action_type: RemoteActionType.UNINSTALL_APP,
  screenshot: true,
  package_name,
})

export const rebootAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.REBOOT,
  remote_action_type: RemoteActionType.REBOOT,
  screenshot: true,
})

export const lockUnlockAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.LOCK_UNLOCK,
  remote_action_type: RemoteActionType.LOCK_UNLOCK,
  screenshot: true,
})

export const displayOnOffAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.DISPLAY_ON_OFF,
  remote_action_type: RemoteActionType.DISPLAY_ON_OFF,
  screenshot: true,
})

export const logAction = ({ lines }: { lines: number }): CommandRequest => ({
  remote_action_id: RemoteActionId.LOG,
  remote_action_type: RemoteActionType.LOG,
  lines,
})

export const adbAction = ({ command }: { command: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.ADB,
  remote_action_type: RemoteActionType.ADB,
  screenshot: true,
  command,
})

export const adbShellAction = ({ command }: { command: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.ADB_SHELL,
  remote_action_type: RemoteActionType.ADB_SHELL,
  screenshot: true,
  command,
})

export const testSpeedAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.TEST_SPEED,
  remote_action_type: RemoteActionType.TEST_SPEED,
})

export const checkOTAUpdatesAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.CHECK_OTA_UPDATES,
  remote_action_type: RemoteActionType.CHECK_OTA_UPDATES,
  screenshot: true,
})

export const getStatusAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.GET_STATUS,
  remote_action_type: RemoteActionType.GET_STATUS,
})

export const keyPressHomeAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_HOME,
  remote_action_type: RemoteActionType.KEY_PRESS_HOME,
  screenshot: true,
  key_code: 3,
})

export const allowRemoteAssistanceAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.ALLOW_REMOTE_ASSISTANCE,
  remote_action_type: RemoteActionType.ALLOW_REMOTE_ASSISTANCE,
})

export const clearTextAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.CLEAR_TEXT,
  remote_action_type: RemoteActionType.CLEAR_TEXT,
  screenshot: true,
  input_text_length: 123,
})

export const volumeAction = (volume_index: number): CommandRequest => ({
  remote_action_id: RemoteActionId.VOLUME,
  remote_action_type: RemoteActionType.VOLUME,
  screenshot: true,
  volume_index,
})

export const clearDataAction = ({ package_name }: { package_name: string }): CommandRequest => ({
  remote_action_id: RemoteActionId.CLEAR_DATA,
  remote_action_type: RemoteActionType.CLEAR_DATA,
  screenshot: true,
  package_name,
})

export const openSocketAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.OPEN_SOCKET,
  remote_action_type: RemoteActionType.OPEN_SOCKET,
})

export const closeSocketAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.CLOSE_SOCKET,
  remote_action_type: RemoteActionType.CLOSE_SOCKET,
})

export const resetAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.RESET,
  remote_action_type: RemoteActionType.RESET,
  screenshot: true,
  type: 1,
})

export const keyPressSettingsAction = (): CommandRequest => ({
  remote_action_id: RemoteActionId.KEY_PRESS_SETTINGS,
  remote_action_type: RemoteActionType.KEY_PRESS_SETTINGS,
  screenshot: true,
})

export const screencastAction = ({ playback, fps }: { playback: 0 | 1 | 2; fps?: number }): CommandRequest => ({
  remote_action_id: RemoteActionId.SCREENCAST,
  remote_action_type: RemoteActionType.SCREENCAST,
  playback,
  fps,
})

export const releaseCycleAction = ({ release_cycle }: { release_cycle: number }) => ({
  remote_action_id: RemoteActionId.RELEASE_CYCLE,
  remote_action_type: RemoteActionType.RELEASE_CYCLE,
  release_cycle,
})

export const deviceLockAction = () => ({
  remote_action_id: RemoteActionId.DEVICE_LOCK,
  remote_action_type: RemoteActionType.DEVICE_LOCK,
  screenshot: true,
})

export const deviceUnlockAction = () => ({
  remote_action_id: RemoteActionId.DEVICE_UNLOCK,
  remote_action_type: RemoteActionType.DEVICE_UNLOCK,
  screenshot: true,
})
