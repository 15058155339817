import DashboardIcon from '@mui/icons-material/Dashboard'
import FaceIcon from '@mui/icons-material/Face'
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import RouterIcon from '@mui/icons-material/Router'
import FingerprintIcon from '@mui/icons-material/Fingerprint'
import AndroidIcon from '@mui/icons-material/Android'
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import SettingsIcon from '@mui/icons-material/Settings'
import { NavigationItem } from './types'
import { RoleId, RouteId } from './enums'

const {
  SYS_OWNER,
  SYS_ADMIN,
  OPR_OWNER,
  OPR_ADMIN,
  OPR_EDITOR,
  OPR_VIEWER,
  OPR_SUPPORT_LINE_1,
  OPR_SUPPORT_LINE_2,
  //
} = RoleId

export const navigation: NavigationItem[] = [
  {
    url: '/dashboard',
    name: 'Dashboard',
    icon: <DashboardIcon />,
    allowedRoles: [SYS_OWNER, SYS_ADMIN, OPR_OWNER, OPR_ADMIN, OPR_EDITOR, OPR_VIEWER],
    isMenu: true,
  },
  {
    url: '/users',
    name: 'Users',
    icon: <FaceIcon />,
    route: RouteId.USER_LIST,
    isMenu: true,
  },
  {
    url: '/operators',
    name: 'Operators',
    icon: <PermDataSettingIcon />,
    route: RouteId.OPERATOR_LIST,
    isMenu: true,
  },
  {
    url: '/alarms',
    name: 'Alarms',
    icon: <PriorityHighIcon />,
    route: RouteId.ALARM_LIST,
    isMenu: true,
  },
  {
    url: '/devices',
    name: 'Devices',
    icon: <RouterIcon />,
    route: RouteId.DEVICE_LIST,
    forbiddenRoles: [SYS_OWNER, OPR_SUPPORT_LINE_1, OPR_SUPPORT_LINE_2],
    isMenu: true,
  },
  {
    url: '/device-profiles',
    name: 'Device Profiles',
    icon: <FingerprintIcon />,
    route: RouteId.DEVICE_PROFILE_LIST,
    forbiddenRoles: [SYS_OWNER],
    isMenu: true,
  },
  {
    url: '/roms',
    name: 'ROMs',
    icon: <AndroidIcon />,
    route: RouteId.ROM_LIST,
    forbiddenRoles: [SYS_OWNER],
    isMenu: true,
  },
  {
    url: '/remote-assistance',
    name: 'Remote Assistance',
    icon: <DesktopWindowsIcon />,
    route: RouteId.SESSION_LIST,
    forbiddenRoles: [SYS_OWNER],
    isMenu: true,
  },
  {
    url: '/admin-configurations',
    name: 'Configurations',
    icon: <SettingsIcon />,
    allowedRoles: [SYS_OWNER, SYS_ADMIN],
    isMenu: true,
  },
  {
    url: '/configurations',
    name: 'Configurations',
    icon: <SettingsIcon />,
    allowedRoles: [OPR_OWNER, OPR_ADMIN],
    isMenu: true,
  },
  {
    url: '/user-profile',
    name: 'User Profile',
    icon: <FaceIcon />,
    isMenu: false,
  },
  {
    url: '/remote-assistance',
    name: 'Remote Session',
    icon: <DesktopWindowsIcon />,
    isMenu: false,
  },
]

export const homeUrlByRole = {
  [SYS_OWNER]: '/dashboard',
  [SYS_ADMIN]: '/dashboard',
  [OPR_OWNER]: '/dashboard',
  [OPR_ADMIN]: '/dashboard',
  [OPR_EDITOR]: '/dashboard',
  [OPR_VIEWER]: '/dashboard',
  [OPR_SUPPORT_LINE_1]: '/remote-assistance',
  [OPR_SUPPORT_LINE_2]: '/remote-assistance',
}
