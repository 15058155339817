import React from 'react'
import { Outlet } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { QueryClientProvider, QueryClient, MutationCache } from '@tanstack/react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { createTheme, ThemeProvider, GlobalStyles } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IResponse } from 'common/interfaces'
import AuthProvider from 'context/auth'
import DialogProvider from 'context/dialog'
import ToastError from 'components/ui/toast-error'

import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSuccess: (res: AxiosResponse<IResponse<any>>, variables, context, mutation) => {
      if (mutation.meta?.skipDefaultOnSuccess) return

      const message = typeof res.data.resource === 'string' ? res.data.resource : res.data.message || 'Success!'

      if (res.status <= 204) {
        toast(message, { type: 'success' })
      }
    },
    onError: (err: AxiosError<IResponse<any>>, variables, context, mutation) => {
      if (mutation.meta?.skipDefaultOnError) return

      toast(<ToastError response={err.response} />, { type: 'error' })
    },
  }),
})

const theme = createTheme({
  palette: {
    primary: {
      main: '#6530F3',
    },
    secondary: {
      main: '#ECC94B',
    },
    success: {
      main: '#16A34A',
    },
    error: {
      main: '#F93751',
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
  },
})

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            '@keyframes mui-auto-fill': { from: { display: 'block' } },
            '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
          }}
        />
        <ToastContainer />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <DialogProvider>
              <Outlet />
            </DialogProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
