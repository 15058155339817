import React from 'react'
import { TableHead as TableHeadUI, TableRow, TableCell, TableSortLabel, Checkbox } from '@mui/material'
import { TableColumn } from 'common/types'
import { Order } from 'common/enums'

export type TableHeadProps<T> = {
  columns: TableColumn<T>[]
  orderBy?: string
  order?: Order
  onOrderByChange?: (orderBy: string) => void
  onOrderChange?: (order: Order) => void
  checkboxSelection?: boolean
  rowsCount: number
  selectedRowsCount: number
  onSelectAllRows: VoidFunction
}

const TableHead = <T,>({
  columns,
  orderBy,
  order,
  onOrderByChange,
  onOrderChange,
  checkboxSelection,
  rowsCount,
  selectedRowsCount,
  onSelectAllRows,
}: TableHeadProps<T>) => {
  const indeterminate = 0 < selectedRowsCount && selectedRowsCount < rowsCount
  const checked = rowsCount > 0 && rowsCount === selectedRowsCount

  return (
    <TableHeadUI>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox color="primary" indeterminate={indeterminate} checked={checked} onChange={onSelectAllRows} />
          </TableCell>
        )}
        {columns.map((column) => {
          const cellProps = {
            align: column.headerAlign,
            sx: { minWidth: column.width, maxWidth: column.width },
          }

          if (!column.isSortable || !onOrderByChange || !onOrderChange) {
            return (
              <TableCell key={column.id} {...cellProps}>
                {column.label}
              </TableCell>
            )
          }

          const sortKey = column.sortKey || column.id
          const isActive = orderBy === sortKey

          const handleClick = () => {
            const isAsc = isActive && order === Order.ASC

            onOrderByChange(sortKey)
            onOrderChange(isAsc ? Order.DESC : Order.ASC)
          }

          return (
            <TableCell key={column.id} {...cellProps}>
              <TableSortLabel active={isActive} direction={isActive ? order : Order.ASC} onClick={handleClick}>
                {column.label}
              </TableSortLabel>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHeadUI>
  )
}

export default React.memo(TableHead) as <T>(props: TableHeadProps<T>) => React.ReactElement
