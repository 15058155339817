import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { IResponse } from 'common/interfaces'
import { unlockAccount } from 'common/api/authorization'
import { Alert } from 'components/ui'
import Loader from 'components/loader'

const UnlockAccountPage = () => {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | undefined>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const mutation = useMutation({
    mutationFn: unlockAccount,
    onSuccess: () => {
      const timeoutId = setTimeout(() => navigate('/login', { replace: true }), 3000)

      setTimeoutId(timeoutId)
    },
    meta: {
      skipDefaultOnSuccess: true,
      skipDefaultOnError: true,
    },
  })

  const { data: mutationData, isSuccess, isError, mutate } = mutation

  const errorData = isError ? (mutation.error as AxiosError<IResponse<null>>).response?.data : undefined

  const token = searchParams.get('token')

  useEffect(() => {
    mutate({ token: token || '' })
  }, [token, mutate])

  useEffect(() => () => clearTimeout(timeoutId), [timeoutId])

  if (isSuccess) {
    return (
      <Alert severity="success" title={mutationData.data.message}>
        You will be redirected back to the login page in a few seconds.
      </Alert>
    )
  }

  if (isError) {
    return (
      <Alert severity="error" title={errorData?.message}>
        {errorData?.error.message}
      </Alert>
    )
  }

  return <Loader />
}

export default UnlockAccountPage
