import React, { createContext, useState, useMemo, useCallback, useEffect } from 'react'
import { IUser } from 'common/models/user'
import { checkAuthentication, getUserProfile, getRBAC } from 'common/api/authorized'
import { Alert } from 'components/ui'
import Loader from 'components/loader'

export type AuthContextValue = {
  isAuthenticated: boolean
  user: IUser | null
  rbac: Record<string, string[]> | null
  setUser: (_: IUser) => void
  setRBAC: (_: Record<string, string[]>) => void
  getAuthentication: (callback?: VoidFunction) => Promise<void>
  clearAuthentication: (callback?: VoidFunction) => void
}

export const AuthContext = createContext<AuthContextValue>({} as AuthContextValue)

export type AuthProviderProps = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [rbac, setRBAC] = useState<Record<string, string[]> | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any | null>(null)

  const isAuthenticated = !!user && !!rbac

  const getAuthentication = useCallback(async (callback?: VoidFunction) => {
    setLoading(true)

    if (await checkAuthentication()) {
      await Promise.all([getUserProfile(), getRBAC()])
        .then(([user, rbac]) => {
          setUser(user.data.resource)
          setRBAC(rbac.data.resource.reduce((acc, cur) => ({ ...acc, [cur.route]: cur.roles }), {}))

          if (callback) callback()
        })
        .catch((error) => {
          setError(error)
        })
    }

    setLoading(false)
  }, [])

  const clearAuthentication = useCallback((callback?: VoidFunction) => {
    setUser(null)
    setRBAC(null)
    setLoading(false)
    setError(null)

    if (callback) callback()
  }, [])

  useEffect(() => {
    getAuthentication()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = useMemo(
    () => ({
      isAuthenticated,
      user,
      rbac,
      setUser,
      setRBAC,
      getAuthentication,
      clearAuthentication,
    }),
    [isAuthenticated, user, rbac, getAuthentication, clearAuthentication],
  )

  const content = useMemo(() => {
    if (loading) return <Loader />

    if (error) return <Alert title="Oops!" text="Couldn't authenticate. Please, try again later." />

    return children
  }, [loading, error, children])

  return <AuthContext.Provider value={value}>{content}</AuthContext.Provider>
}

export default AuthProvider
