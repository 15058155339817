import React from 'react'
import { Pagination } from '@mui/material'

export type TablePaginationProps = {
  pagesCount?: number
  page?: number
  onPageChange: (page: number) => void
}

const TablePagination = ({ pagesCount, page, onPageChange }: TablePaginationProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => onPageChange(value)

  return <Pagination variant="outlined" color="primary" count={pagesCount} page={page} onChange={handleChange} />
}

export default React.memo(TablePagination)
