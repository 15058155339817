import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import {
  ISession,
  GetSessionsParams,
  CreateSessionParams,
  UpdateSessionParams,
  ISessionAction,
  GetSessionActionsParams,
  CreateSessionActionParams,
} from '../models/remote-assistance'
import { openSocketAction } from '../remote-actions'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-remote-assistance-sessions`
const apiActionsPrefix = `${CORE_API_DOMAIN}/operator-remote-assistance-session-actions`

export const getSessions = ({
  sequential_id,
  provision_id,
  service_desk_id,
  serial_number,
  mac_address,
  orderBy,
  order,
  limit,
  page,
}: GetSessionsParams = {}) =>
  axios.get<IResponse<ITableResource<ISession>>>(apiPrefix, {
    params: {
      'filter[sequential_id]': sequential_id,
      'filter[provision_id]': provision_id,
      'filter[service_desk_id]': service_desk_id,
      'filter[serial_number]': serial_number,
      'filter[mac_address]': mac_address,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const getSessionById = (id: string) => axios.get<IResponse<ISession>>(`${apiPrefix}/${id}`)

export const createSession = (data: CreateSessionParams) => axios.post<IResponse<ISession>>(apiPrefix, data)

export const updateSession = ({ id, ...data }: UpdateSessionParams) =>
  axios.put<IResponse<ISession>>(`${apiPrefix}/${id}`, data)

export const getSessionActions = ({
  operator_remote_assistance_session_id,
  orderBy,
  order,
  limit,
  page,
}: GetSessionActionsParams = {}) =>
  axios.get<IResponse<ITableResource<ISessionAction>>>(apiActionsPrefix, {
    params: {
      'filter[operator_remote_assistance_session_id]': operator_remote_assistance_session_id,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const createSessionAction = (data: CreateSessionActionParams) =>
  axios.post<IResponse<ISessionAction>>(apiActionsPrefix, data)

export const openSocket = (device_id: string) =>
  axios.post(`${CORE_API_DOMAIN}/push/socket/open`, {
    ...openSocketAction(),
    device_id,
    remote_action: 1,
    channel_action: 1,
  })

export const hostAPK = (data: { file: File }) =>
  axios.postForm<IResponse<{ url: string }>>(`${CORE_API_DOMAIN}/apk/host`, data)
