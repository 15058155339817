import React from 'react'
import { twMerge } from 'tailwind-merge'

type CardProps = {
  children: React.ReactNode
  className?: string
}

const Card = ({ children, className }: CardProps) => {
  const cn = twMerge('h-full py-4 px-4 rounded-xl border border-gray-200 bg-white', className)
  return <div className={cn}>{children}</div>
}

export default Card
