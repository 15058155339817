import React from 'react'
import { twMerge } from 'tailwind-merge'

const releaseCycles = [
  { id: 5, name: 'Release', shortLabel: 'R', bgColor: 'bg-primary text-white' },
  { id: 4, name: 'Release Candidate', shortLabel: 'RC', bgColor: 'bg-green-400 text-white' },
  { id: 3, name: 'Beta', shortLabel: 'B', bgColor: 'bg-green-400' },
  { id: 2, name: 'Alpha', shortLabel: 'A', bgColor: 'bg-blue-600 text-white' },
  { id: 1, name: 'Quality Assurance', shortLabel: 'QA', bgColor: 'bg-blue-400 text-white' },
  { id: 0, name: 'Development', shortLabel: 'DEV', bgColor: 'bg-red text-white' },
]

const ReleaseBadge = ({ release, shortLabel }: { release?: number; shortLabel?: string }) => {
  const r = releaseCycles.find((r) => r.id === release || r.shortLabel === shortLabel)

  return (
    <span
      className={twMerge(
        'inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-1',
        r?.bgColor,
      )}
    >
      {r?.shortLabel}
    </span>
  )
}

export default ReleaseBadge
