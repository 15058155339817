import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'

export type TableToolbarProps<T> = {
  title?: string
  renderActions?: () => React.ReactNode
  renderSelectedRowsActions?: (selectedRows: T[]) => React.ReactNode
  selectedRows: T[]
}

const TableToolbar = <T,>({ title, renderActions, renderSelectedRowsActions, selectedRows }: TableToolbarProps<T>) => {
  const selectedRowsCount = selectedRows.length
  const isSelected = selectedRowsCount > 0

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { sm: 1, xs: 1 },
        ...(isSelected && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {isSelected ? (
        <>
          <Typography className="flex flex-1" component="div" variant="subtitle1">
            {selectedRowsCount} selected
          </Typography>
          {renderSelectedRowsActions?.(selectedRows)}
        </>
      ) : (
        <>
          <Typography className="flex flex-1" component="div" variant="h6">
            {title}
          </Typography>
          {renderActions?.()}
        </>
      )}
    </Toolbar>
  )
}

export default React.memo(TableToolbar) as <T>(props: TableToolbarProps<T>) => React.ReactElement | null
