import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useLocation, useNavigate, Outlet, Link, NavLink } from 'react-router-dom'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import MenuIcon from '@mui/icons-material/Menu'
import FaceIcon from '@mui/icons-material/Face'
import CloseIcon from '@mui/icons-material/Close'
import { wbcVersion } from 'API_routes'
import packageInfo from '../../../package.json'
import { twMerge } from 'tailwind-merge'
import { navigation } from 'common/navigation'
import { doLogout } from 'common/api/authorized'
import useAuth from 'hooks/useAuth'
import usePermissions from 'hooks/usePermissions'

const Layout = () => {
  const { user, clearAuthentication } = useAuth()

  const hasPermissions = usePermissions()

  const location = useLocation()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const logoutMutation = useMutation({
    mutationFn: doLogout,
    onSuccess: () => {
      clearAuthentication(() => {
        queryClient.clear()

        navigate('/login', { replace: true })
      })
    },
  })

  const logout = useCallback(() => logoutMutation.mutate(), [logoutMutation])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    setSidebarOpen(false)
  }, [location])

  const profileMenu = useMemo(
    () => (
      <>
        <Menu.Item>
          <Link to="/user-profile" className="block px-3 py-1 text-sm leading-6 text-gray-900">
            User Profile
          </Link>
        </Menu.Item>
        <Menu.Item>
          <button className="block px-3 py-1 text-sm leading-6 text-gray-900" onClick={logout}>
            Sign Out
          </button>
        </Menu.Item>
      </>
    ),
    [logout],
  )

  const mainMenu = useMemo(
    () =>
      navigation
        .filter(({ isMenu }) => isMenu)
        .filter(({ route, allowedRoles, forbiddenRoles }) => hasPermissions({ route, allowedRoles, forbiddenRoles }))
        .map((item) => (
          <li key={item.name}>
            <NavLink
              to={item.url}
              className={({ isActive }) => {
                return twMerge(
                  isActive ? 'bg-gray-800 text-white' : 'text-gray-400  hover:text-white hover:bg-gray-800',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                )
              }}
            >
              {item.icon}
              {item.name}
            </NavLink>
          </li>
        )),
    [hasPermissions],
  )

  const version = packageInfo.version as string

  const versionInfo = useMemo(() => {
    return (
      <>
        <div>
          <span className="text-xs text-gray-400 block font-semibold">UI Version: {version}</span>
          <span className="text-xs text-gray-400 block font-semibold">ADM: {wbcVersion}</span>
        </div>
      </>
    )
  }, [version])

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 xl:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <CloseIcon />
                        {/* <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center justify-center">
                      <img className="h-8 w-auto" src="/images/agile-logo-negative.png" alt="WBC logo" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <div role="list" className="flex flex-1 flex-col gap-y-7">
                        <div>
                          <ul className="-mx-2 space-y-1">{mainMenu}</ul>
                        </div>
                        <div className="mt-auto">{versionInfo}</div>
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center justify-center">
              <img className="h-12 w-auto" src="/images/agile-logo-negative.png" alt="WBC logo" />
            </div>
            <nav className="flex flex-1 flex-col">
              <div className="flex flex-1 flex-col gap-y-7">
                <div>
                  <ul className="-mx-2 space-y-1">{mainMenu}</ul>
                </div>
                <div className="mt-auto">{versionInfo}</div>
              </div>
            </nav>
          </div>
        </div>

        <div className="xl:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 xl:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 xl:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <MenuIcon />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 xl:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch xl:gap-x-6 flex-end justify-end">
              <div className="flex items-center gap-x-4 xl:gap-x-6">
                {/* Separator */}
                <div className="hidden xl:block xl:h-6 xl:w-px xl:bg-gray-900/10" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <FaceIcon className="h-8 w-8 rounded-full bg-gray-50" />

                    <span className="hidden xl:flex xl:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {user!.name}
                      </span>
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {profileMenu}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-4">
            <div className="px-4 sm:px-6 xl:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Layout
