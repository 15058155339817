import { AUTH_BASE_URL } from '../../API_routes'
import { IResponse, ITableResource, ISelectOption } from '../interfaces'
import { IOperator, GetOperatorsParams, CreateOperatorParams, UpdateOperatorParams } from '../models/operator'
import axios from '../axiosInstance'

const apiPrefix = `${AUTH_BASE_URL}/operators`

export const getOperators = ({
  name,
  type_id,
  service_type_id,
  status,
  orderBy,
  order,
  ...rest
}: GetOperatorsParams = {}) =>
  axios.get<IResponse<ITableResource<IOperator>>>(apiPrefix, {
    params: {
      'filter[name]': name,
      'filter[type_id]': type_id,
      'filter[service_type_id]': service_type_id,
      'filter[status]': status,
      order: orderBy,
      direction: order,
      ...rest,
    },
  })

export const createOperator = (data: CreateOperatorParams) => axios.post<IResponse<IOperator>>(apiPrefix, data)

export const updateOperator = ({ id, ...data }: UpdateOperatorParams) =>
  axios.put<IResponse<IOperator>>(`${apiPrefix}/${id}`, data)

export const deleteOperators = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })

export const getOperatorTypes = () => axios.get<IResponse<ISelectOption[]>>(`${AUTH_BASE_URL}/list/operators/types`)

export const getOperatorServiceTypes = () =>
  axios.get<IResponse<ISelectOption[]>>(`${AUTH_BASE_URL}/list/operators/service-types`)
