import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { IResponse, IResponseError, IAuthToken } from 'common/interfaces'
import { doLoginOtp } from 'common/api/authorization'
import useAuth from 'hooks/useAuth'
import { Alert } from 'components/ui'
import SubmitButton from 'components/forms/submit-button'

type FormValues = {
  token: string
}

const Login2FAPage = () => {
  const { getAuthentication } = useAuth()

  const location = useLocation()
  const navigate = useNavigate()

  const from = location.state?.from?.pathname || '/'

  const { register, handleSubmit } = useForm<FormValues>()

  const mutation = useMutation({
    mutationFn: doLoginOtp,
    onSuccess: () => {
      getAuthentication(() => navigate(from, { replace: true }))
    },
    meta: {
      skipDefaultOnError: true,
    },
  })

  const { isPending, isError, mutate } = mutation

  const error = isError
    ? (mutation.error as AxiosError<IResponse<IAuthToken, IResponseError>>).response?.data
    : undefined

  const onSubmit: SubmitHandler<FormValues> = (formValues) => mutate(formValues)

  return (
    <>
      <h2 className="mb-4 text-center text-primary text-3xl font-bold tracking-tight">Two factor authentication</h2>
      <div className="mb-6 text-center">To proceed with login you must enter the OTP token sent to your email.</div>
      {isError && <Alert className="mb-4" title={error?.message} text={error?.error.message} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextField
            label="OTP token"
            placeholder="OTP token"
            variant="outlined"
            fullWidth
            required
            {...register('token')}
          />
        </div>
        <div className="flex justify-end mt-6">
          <SubmitButton caption="Log in" isPending={isPending} />
        </div>
      </form>
    </>
  )
}

export default Login2FAPage
