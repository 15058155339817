import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, IAdminConfig } from '../interfaces'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/admin-configs`

export const getAdminConfig = () => axios.get<{ data: IAdminConfig }>(apiPrefix)

export const updateAdminConfig = (data: IAdminConfig) => axios.post(apiPrefix, data)

export const getHandshakeValidationOptions = () =>
  axios.get<IResponse<{ data: string[] }>>(`${apiPrefix}/handshake-validation-options`)

export const getRemoteSecretKey = () => axios.get<IResponse<string>>(`${apiPrefix}/remote-secret-key`)
