import { Backdrop, CircularProgress } from '@mui/material'
import React, { useMemo } from 'react'

type LoaderProps = {
  full?: boolean
}

function Loader({ full }: LoaderProps) {
  const loader = useMemo(() => <CircularProgress color="primary" />, [])

  return full ? (
    <Backdrop className="z-10" open={true}>
      {loader}
    </Backdrop>
  ) : (
    <div className="flex justify-center py-4">{loader}</div>
  )
}

export default Loader
