import React, { useMemo, useRef, useState, useEffect } from 'react'
import { flushSync } from 'react-dom'

type DataType = { x: number | string | undefined; y: number }[]

const useVictoryScrollDelay = (data: DataType, ref: React.RefObject<any>): [DataType, boolean] => {
  const emptyData = useMemo(() => data.map(({ x }) => ({ x, y: 0 })), [data])
  const observerRef = useRef<{ observer: IntersectionObserver | null }>({ observer: null })
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    observerRef.current.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          flushSync(() => setIsVisible(true))

          observerRef.current.observer?.disconnect()
        }
      },
      { threshold: 0.8 },
    )

    observerRef.current.observer.observe(ref.current)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => observerRef.current.observer?.disconnect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [isVisible ? data : emptyData, isVisible]
}

export default useVictoryScrollDelay
