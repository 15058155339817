import { queryOptions } from '@tanstack/react-query'
import { QueryType } from './enums'
import { GetUsersParams } from './models/user'
import { GetOperatorsParams } from './models/operator'
import { GetAlarmsParams } from './models/alarm'
import { GetDevicesParams } from './models/device'
import { GetBulkActionsParams, GetBulkActionsPreviewParams } from './models/bulk-action'
import {
  GetDeviceProfilesParams,
  GetAppsParams,
  GetAppPermissionsParams,
  GetAppConfigurationsParams,
} from './models/device-profile'
import { GetROMsParams } from './models/rom'
import { GetSessionsParams, GetSessionActionsParams } from './models/remote-assistance'
import { getRoles } from './api/common'
import { getLoginOptions } from './api/authorization'
import { getUsers, getUserRoles, getUserOperators } from './api/users'
import { getOperators, getOperatorTypes, getOperatorServiceTypes } from './api/operators'
import { getAlarms, getAlarmsCount } from './api/alarms'
import { getDevices, getDeviceById, getDeviceData, getDeviceStatusV2, getDeviceStatus } from './api/devices'
import { getBulkActions, getBulkActionsPreview } from './api/bulk-actions'
import {
  getDeviceProfiles,
  getDeviceProfileById,
  getDeviceProfilePlaybackSupports,
  getApps,
  getAppById,
  getAppPermissions,
  getAppConfigurations,
  getDeviceProfileROMs,
  getDeviceProfileConfigurations,
} from './api/device-profiles'
import { getROMs } from './api/roms'
import { getSessions, getSessionById, getSessionActions } from './api/remote-assistance'
import { getAdminConfig, getHandshakeValidationOptions, getRemoteSecretKey } from './api/admin-config'
import { getOperatorConfig } from './api/operator-config'

export const loginOptions = () =>
  queryOptions({
    queryKey: [QueryType.LOGIN_OPTIONS],
    queryFn: () => getLoginOptions().then((res) => res.data.resource),
  })

export const usersOptions = (params: GetUsersParams = {}) =>
  queryOptions({
    queryKey: [QueryType.USERS, params],
    queryFn: () => getUsers(params).then((res) => res.data.resource),
  })

export const userRolesOptions = () =>
  queryOptions({
    queryKey: [QueryType.USER_ROLES],
    queryFn: () => getUserRoles().then((res) => res.data.resource),
    staleTime: Infinity,
  })

export const userOperatorsOptions = () =>
  queryOptions({
    queryKey: [QueryType.USER_OPERATORS],
    queryFn: () => getUserOperators().then((res) => res.data.resource),
    // staleTime: Infinity,
  })

export const operatorsOptions = (params: GetOperatorsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.OPERATORS, params],
    queryFn: () => getOperators(params).then((res) => res.data.resource),
  })

export const operatorTypesOptions = () =>
  queryOptions({
    queryKey: [QueryType.OPERATOR_TYPES],
    queryFn: () => getOperatorTypes().then((res) => res.data.resource),
    staleTime: Infinity,
  })

export const operatorServiceTypesOptions = () =>
  queryOptions({
    queryKey: [QueryType.OPERATOR_SERVICE_TYPES],
    queryFn: () => getOperatorServiceTypes().then((res) => res.data.resource),
    staleTime: Infinity,
  })

export const alarmsOptions = (params: GetAlarmsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.ALARMS, params],
    queryFn: () => getAlarms(params).then((res) => res.data.resource),
  })

export const alarmsCountOptions = (params: { type: string }) =>
  queryOptions({
    queryKey: [QueryType.ALARMS_COUNT, params],
    queryFn: () => getAlarmsCount(params).then((res) => res.data.resource),
  })

export const devicesOptions = (params: GetDevicesParams = {}) =>
  queryOptions({
    queryKey: [QueryType.DEVICES, params],
    queryFn: () => getDevices(params).then((res) => res.data.resource),
  })

export const deviceByIdOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_BY_ID, id],
    queryFn: () => getDeviceById(id).then((res) => res.data.resource),
  })

export const deviceDataOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_DATA, id],
    queryFn: () => getDeviceData(id).then((res) => res.data.resource),
  })

export const deviceStatusV2Options = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_STATUS_V2, id],
    queryFn: () => getDeviceStatusV2(id).then((res) => res.data.resource || null),
  })

export const deviceStatusOptions = (mac: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_STATUS, mac],
    queryFn: () => getDeviceStatus(mac).then((res) => res.data.resource.data[0] || null),
  })

export const bulkActionsOptions = (params: GetBulkActionsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.BULK_ACTIONS, params],
    queryFn: () => getBulkActions(params).then((res) => res.data.resource),
  })

export const bulkActionsPreviewOptions = (params: GetBulkActionsPreviewParams) =>
  queryOptions({
    queryKey: [QueryType.BULK_ACTIONS_PREVIEW, params],
    queryFn: () => getBulkActionsPreview(params).then((res) => res.data.resource),
  })

export const deviceProfilesOptions = (params: GetDeviceProfilesParams = {}) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_PROFILES, params],
    queryFn: () => getDeviceProfiles(params).then((res) => res.data.resource),
  })

export const deviceProfileByIdOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_PROFILE_BY_ID, id],
    queryFn: () => getDeviceProfileById(id).then((res) => res.data.resource),
  })

export const deviceProfilePlaybackSupportsOptions = () =>
  queryOptions({
    queryKey: [QueryType.DEVICE_PROFILE_PLAYBACK_SUPPORTS],
    queryFn: () => getDeviceProfilePlaybackSupports().then((res) => res.data.resource.data),
    staleTime: Infinity,
  })

export const appsOptions = (params: GetAppsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.APPS, params],
    queryFn: () => getApps(params).then((res) => res.data.data),
  })

export const appByIdOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.APP_BY_ID, id],
    queryFn: () => getAppById(id).then((res) => res.data.data),
  })

export const appPermissionsOptions = (params: GetAppPermissionsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.APP_PERMISSIONS, params],
    queryFn: () => getAppPermissions(params).then((res) => res.data.resource),
  })

export const appConfigurationsOptions = (params: GetAppConfigurationsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.APP_CONFIGURATIONS, params],
    queryFn: () => getAppConfigurations(params).then((res) => res.data.resource),
  })

export const deviceProfileROMsOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_PROFILE_ROMS, id],
    queryFn: () => getDeviceProfileROMs(id).then((res) => res.data.data),
  })

export const deviceProfileConfigurationsOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.DEVICE_PROFILE_CONFIGURATIONS, id],
    queryFn: () => getDeviceProfileConfigurations(id).then((res) => res.data.data),
  })

export const romsOptions = (params: GetROMsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.ROMS, params],
    queryFn: () => getROMs(params).then((res) => res.data.resource),
  })

export const sessionsOptions = (params: GetSessionsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.SESSIONS, params],
    queryFn: () => getSessions(params).then((res) => res.data.resource),
  })

export const sessionByIdOptions = (id: string) =>
  queryOptions({
    queryKey: [QueryType.SESSION_BY_ID, id],
    queryFn: () => getSessionById(id).then((res) => res.data.resource),
  })

export const sessionActionsOptions = (params: GetSessionActionsParams = {}) =>
  queryOptions({
    queryKey: [QueryType.SESSION_ACTIONS, params],
    queryFn: () => getSessionActions(params).then((res) => res.data.resource),
  })

export const adminConfigOptions = () =>
  queryOptions({
    queryKey: [QueryType.ADMIN_CONFIG],
    queryFn: () => getAdminConfig().then(({ data }) => data.data),
  })

export const operatorConfigOptions = () =>
  queryOptions({
    queryKey: [QueryType.OPERATOR_CONFIG],
    queryFn: () => getOperatorConfig().then(({ data }) => data.data),
  })

// TODO: Remove since it duplicates userRolesOptions().
export const rolesOptions = () =>
  queryOptions({
    queryKey: [QueryType.ROLES],
    queryFn: () => getRoles().then(({ data }) => data.resource),
  })

export const handshakeValidationOptions = () =>
  queryOptions({
    queryKey: [QueryType.HANDSHAKE_VALIDATION_OPTIONS],
    queryFn: () =>
      getHandshakeValidationOptions().then(({ data }) =>
        data.resource.data.map((name, id) => ({ id: String(id), name })),
      ),
  })

export const remoteSecretKeyOptions = () =>
  queryOptions({
    queryKey: [QueryType.REMOTE_SECRET_KEY],
    queryFn: () => getRemoteSecretKey().then(({ data }) => data.resource),
  })
