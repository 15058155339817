import React, { createContext, useRef, useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Dialog as DialogUI, DialogProps as DialogPropsUI } from '@mui/material'
import { CommonDialogProps, RenderDialog, OpenDialog } from 'common/types'

export const DialogContext = createContext<OpenDialog>((() => Promise.resolve()) as OpenDialog)

export type DialogProviderRef = {
  renderDialog: RenderDialog<any>
  dialogPropsUI?: Omit<DialogPropsUI, 'open' | 'onClose'>
} & Omit<CommonDialogProps<any>, 'open'>

const dialogProviderRefInitialValue: DialogProviderRef = {
  renderDialog: () => null,
  resolve: () => {},
  reject: () => {},
}

const dialogDefaultProps: Omit<DialogPropsUI, 'open' | 'onClose'> = {
  fullWidth: true,
  maxWidth: 'md',
  scroll: 'body',
}

export type DialogProviderProps = {
  children: React.ReactNode
}

const DialogProvider = ({ children }: DialogProviderProps) => {
  const ref = useRef<DialogProviderRef>(dialogProviderRefInitialValue)
  const nestedRef = useRef<DialogProviderRef>(dialogProviderRefInitialValue)

  const [open, setOpen] = useState(false)
  const [nestedOpen, setNestedOpen] = useState(false)

  const openDialog = useCallback(
    <T = void,>(renderDialog: RenderDialog<T>, dialogPropsUI?: Omit<DialogPropsUI, 'open' | 'onClose'>) =>
      new Promise<T>((resolve, reject) => {
        if (nestedOpen) throw new Error('Dialog stack exceeded. The maximum number of dialogs opened is 2.')

        if (open) {
          const resolveAndClose = (value: T) => {
            setNestedOpen(false)
            resolve(value)
          }

          const rejectAndClose = () => {
            setNestedOpen(false)
            reject()
          }

          nestedRef.current = {
            renderDialog,
            dialogPropsUI,
            resolve: resolveAndClose,
            reject: rejectAndClose,
          }

          setNestedOpen(true)

          return
        }

        const resolveAndClose = (value: T) => {
          setOpen(false)
          resolve(value)
        }

        const rejectAndClose = () => {
          setOpen(false)
          reject()
        }

        ref.current = {
          renderDialog,
          dialogPropsUI,
          resolve: resolveAndClose,
          reject: rejectAndClose,
        }

        setOpen(true)
      }),
    [open, nestedOpen],
  )

  const location = useLocation()

  useEffect(() => {
    setNestedOpen(false)
    setOpen(false)
  }, [location])

  return (
    <DialogContext.Provider value={openDialog}>
      {children}
      <DialogUI {...dialogDefaultProps} {...ref.current.dialogPropsUI} open={open} onClose={ref.current.reject}>
        {ref.current.renderDialog({ open, resolve: ref.current.resolve, reject: ref.current.reject })}
        <DialogUI
          {...dialogDefaultProps}
          {...nestedRef.current.dialogPropsUI}
          open={nestedOpen}
          onClose={nestedRef.current.reject}
        >
          {nestedRef.current.renderDialog({
            open: nestedOpen,
            resolve: nestedRef.current.resolve,
            reject: nestedRef.current.reject,
          })}
        </DialogUI>
      </DialogUI>
    </DialogContext.Provider>
  )
}

export default DialogProvider
