import React, { useRef, useMemo } from 'react'
import { VictoryStyleInterface, VictoryChart, VictoryBar, VictoryTooltip, VictoryTheme } from 'victory'
import { useVictoryScrollDelay } from 'hooks'

const animate = { duration: 500, onLoad: { duration: 500 } }

export type ChartBarProps = {
  data: { x: number | string | undefined; y: number }[]
  style?: VictoryStyleInterface
}

const ChartBar = ({ data, style }: ChartBarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [visibleData] = useVictoryScrollDelay(data, ref)

  const domain = useMemo(() => {
    const maxValueY = data.reduce((acc, cur) => (cur.y > acc ? cur.y : acc), 0)
    const maxDomainY = maxValueY > 10 ? maxValueY : 10

    return { y: [0, maxDomainY] } as { y: [number, number] }
  }, [data])

  const labels = useMemo(() => data.map(({ y }) => String(y)), [data])

  return (
    <div ref={ref}>
      <VictoryChart theme={VictoryTheme.material} domain={domain} domainPadding={12}>
        <VictoryBar
          data={visibleData}
          style={style}
          animate={animate}
          labels={labels}
          labelComponent={<VictoryTooltip />}
        />
      </VictoryChart>
    </div>
  )
}

export default ChartBar
