import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { DetailsItem } from 'common/types'

export type DetailsProps<T> = {
  title?: string
  renderImage?: () => React.ReactNode
  renderActions?: () => React.ReactNode
  data: T
  details: DetailsItem<T>[]
}

const Details = <T,>({ title, renderImage, renderActions, data, details }: DetailsProps<T>) => {
  const isToolbarVisible = !!title || !!renderImage || !!renderActions

  return (
    <>
      {isToolbarVisible && (
        <Toolbar className="flex-wrap gap-4 mb-4" disableGutters sx={{ pr: { sm: 1, xs: 1 } }}>
          {renderImage?.()}
          <Typography className="flex flex-1" component="div" variant="h6">
            {title}
          </Typography>
          {renderActions?.()}
        </Toolbar>
      )}
      <dl className="grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5">
        {details.map(({ id, label, valueGetter, render }) => (
          <div key={id}>
            <dt className="text-sm font-semibold text-gray-400">{label}</dt>
            <dd>
              {!!render ? (
                render(data)
              ) : (
                <div className="text-base font-normal">
                  {!!valueGetter ? valueGetter(data) : String(data[id] ?? '')}
                  {/*  */}
                </div>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </>
  )
}

export default React.memo(Details) as <T>(props: DetailsProps<T>) => React.ReactElement
