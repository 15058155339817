import React from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { isAxiosError } from 'axios'
import { Alert, AlertTitle, IconButton, Tooltip } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import Loader from '../loader'

export type QuerySuspenseBoundaryProps = {
  children?: React.ReactNode
}

const QuerySuspenseBoundary = ({ children }: QuerySuspenseBoundaryProps) => {
  const location = useLocation()

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          key={location.pathname}
          fallbackRender={({ error, resetErrorBoundary }) => {
            if (!isAxiosError(error)) throw error

            return (
              <Alert
                severity="error"
                action={
                  <Tooltip title="Retry">
                    <IconButton size="small" color="inherit" onClick={resetErrorBoundary}>
                      <RefreshIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                }
              >
                <AlertTitle>Request Error</AlertTitle>
                Couldn't load data. Please, try again.
              </Alert>
            )
          }}
          onReset={reset}
        >
          <React.Suspense fallback={<Loader />}>{children}</React.Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  )
}

export default QuerySuspenseBoundary
