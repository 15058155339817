import { Alert as A, AlertTitle } from '@mui/material'
import React from 'react'
import { twMerge } from 'tailwind-merge'

type AlertProps = {
  text?: string
  title?: string
  className?: string
  severity?: 'success' | 'info' | 'warning' | 'error'
  children?: React.ReactNode
}

const Alert = ({ text, className, children, title, severity = 'error' }: AlertProps) => {
  const cn = twMerge('w-full', className)

  return (
    <A severity={severity} className={cn}>
      <AlertTitle className="capitalize">{title ?? severity}</AlertTitle>
      {text && text}
      <>{children}</>
    </A>
  )
}

export default Alert
