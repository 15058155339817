import React from 'react'
import { Outlet } from 'react-router-dom'
import { usePermissions, HasPermissionsParams } from 'hooks'

export type RestrictedPageProps = HasPermissionsParams

const RestrictedPage = ({ ...params }: RestrictedPageProps) => {
  const hasPermissions = usePermissions()

  if (!hasPermissions(params)) {
    throw new Response('Not Found', { status: 404 })
  }

  return <Outlet />
}

export default RestrictedPage
