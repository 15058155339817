import React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import { TextField } from '@mui/material'
import { AxiosError } from 'axios'
import { IResponse, IResponseDetailedError } from 'common/interfaces'
import { forgotPassword } from 'common/api/authorization'
import { Alert } from 'components/ui'
import SubmitButton from 'components/forms/submit-button'

type FormValues = {
  email: string
}

const ForgotPasswordPage = () => {
  const { register, handleSubmit } = useForm<FormValues>()
  const mutation = useMutation({
    mutationFn: forgotPassword,
    meta: {
      skipDefaultOnSuccess: true,
      skipDefaultOnError: true,
    },
  })

  const { data: mutationData, isPending, isSuccess, isError, mutate } = mutation

  const error = isError
    ? (mutation.error as AxiosError<IResponse<null, IResponseDetailedError>>).response?.data.error
    : undefined

  const onSubmit: SubmitHandler<FormValues> = (formValues) => mutate(formValues)

  return (
    <>
      <h2 className="mb-8 text-center text-primary text-3xl font-bold tracking-tight">Forgot your password?</h2>
      {isSuccess && <Alert className="mb-4" severity="success" title={mutationData.data.message} />}
      {isError && (
        <Alert className="mb-4" severity="error" title={error?.message}>
          {Object.entries(error?.details || {}).map(([key, value]) => (
            <div key={key}>{value}</div>
          ))}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <TextField label="Email" variant="outlined" fullWidth type="email" required {...register('email')} />
        </div>
        <div className="flex justify-end mt-6">
          <SubmitButton caption="Send reset link" isDisabled={isSuccess} isPending={isPending} />
        </div>
      </form>
    </>
  )
}

export default ForgotPasswordPage
