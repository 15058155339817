import { CORE_API_DOMAIN } from '../../API_routes'
import { IResponse, ITableResource } from '../interfaces'
import { IROM, GetROMsParams, CreateROMParams, UpdateROMParams } from '../models/rom'
import axios from '../axiosInstance'

const apiPrefix = `${CORE_API_DOMAIN}/operator-roms`

export const getROMs = ({
  name,
  build_number,
  kernel_version,
  baseband_version,
  ui_version,
  status,
  wipe_data,
  force_update,
  is_incremental,
  a_b,
  orderBy,
  order,
  limit,
  page,
}: GetROMsParams = {}) =>
  axios.get<IResponse<ITableResource<IROM>>>(apiPrefix, {
    params: {
      'filter[name]': name,
      'filter[build_number]': build_number,
      'filter[kernel_version]': kernel_version,
      'filter[baseband_version]': baseband_version,
      'filter[ui_version]': ui_version,
      'filter[status]': status,
      'filter[wipe_data]': wipe_data,
      'filter[force_update]': force_update,
      'filter[is_incremental]': is_incremental,
      'filter[a_b]': a_b,
      order: orderBy,
      direction: order,
      limit,
      page,
    },
  })

export const createROM = (data: CreateROMParams) => axios.post<IResponse<IROM>>(apiPrefix, data)

export const updateROM = ({ id, ...data }: UpdateROMParams) => axios.put<IResponse<IROM>>(`${apiPrefix}/${id}`, data)

export const deleteROMs = (ids: string[]) =>
  axios.delete(apiPrefix, {
    params: ids.reduce((params, id, index) => ({ ...params, [`ids[${index}]`]: id }), {}),
  })
