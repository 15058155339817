import React from 'react'
import { TableContainer, Table as TableUI, TableBody, TableRow, TableCell, Checkbox } from '@mui/material'
import InboxIcon from '@mui/icons-material/Inbox'
import { TableColumn } from 'common/types'
import { useSelectedRows } from 'hooks'
import TableToolbar, { TableToolbarProps } from './TableToolbar'
import TableHead, { TableHeadProps } from './TableHead'
import TablePagination, { TablePaginationProps } from './TablePagination'

export type TableProps<T> = {
  getKey?: (row: T) => string
  columns: TableColumn<T>[]
  rows: T[]
} & Pick<TableToolbarProps<T>, 'title' | 'renderActions' | 'renderSelectedRowsActions'> &
  Pick<TableHeadProps<T>, 'orderBy' | 'order' | 'onOrderByChange' | 'onOrderChange' | 'checkboxSelection'> &
  Partial<TablePaginationProps>

const Table = <T,>({
  getKey,
  title,
  renderActions,
  renderSelectedRowsActions,
  columns,
  rows,
  orderBy,
  order,
  onOrderByChange,
  onOrderChange,
  checkboxSelection = false,
  pagesCount,
  page,
  onPageChange,
}: TableProps<T>) => {
  const { selectedRows, selectRow, selectAllRows, isSelected } = useSelectedRows(rows)

  const rowsCount = rows.length
  const selectedRowsCount = selectedRows.length

  const isToolbarVisible = !!title || !!renderActions || (!!renderSelectedRowsActions && selectedRowsCount > 0)
  const isPaginationVisible = !!onPageChange

  return (
    <>
      {isToolbarVisible && (
        <TableToolbar<T>
          title={title}
          renderActions={renderActions}
          renderSelectedRowsActions={renderSelectedRowsActions}
          selectedRows={selectedRows}
        />
      )}
      <TableContainer>
        <TableUI>
          <TableHead<T>
            columns={columns}
            orderBy={orderBy}
            order={order}
            onOrderByChange={onOrderByChange}
            onOrderChange={onOrderChange}
            checkboxSelection={checkboxSelection}
            rowsCount={rowsCount}
            selectedRowsCount={selectedRowsCount}
            onSelectAllRows={selectAllRows}
          />
          <TableBody>
            {rowsCount === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + Number(checkboxSelection)}>
                  <div className="flex flex-col items-center gap-1">
                    <InboxIcon className="!text-6xl" color="disabled" />
                    <div className="text-trueGray-400">No data found</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {rows.map((row) => {
              const selected = isSelected(row)
              const onSelectRow = () => selectRow(row)

              return (
                <TableRow key={getKey ? getKey(row) : row['id']} selected={selected} hover>
                  {checkboxSelection && (
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" checked={selected} onChange={onSelectRow} />
                    </TableCell>
                  )}
                  {columns.map((column) => {
                    const cellProps = {
                      align: column.align,
                      sx: { minWidth: column.width, maxWidth: column.width },
                    }

                    if (!!column.renderCell) {
                      return (
                        <TableCell key={column.id} {...cellProps}>
                          {column.renderCell(row)}
                        </TableCell>
                      )
                    }

                    const title = !!column.valueGetter ? column.valueGetter(row) : String(row[column.id] ?? '')

                    return (
                      <TableCell key={column.id} {...cellProps}>
                        <div className="truncate" title={title}>
                          {title}
                        </div>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </TableUI>
      </TableContainer>
      {isPaginationVisible && (
        <div className="flex justify-end mt-4">
          <TablePagination pagesCount={pagesCount} page={page} onPageChange={onPageChange} />
        </div>
      )}
    </>
  )
}

export default React.memo(Table) as <T>(props: TableProps<T>) => React.ReactElement
